import React, { Component } from 'react'
import { StyleSheet, View, TextStyle, StyleProp, ViewStyle } from 'react-native'
import config from '../../shared/config'
import { Box, Text } from '../'

interface Props {
  dayName: string
  dayDigit: string
  monthName: string
  onPress: () => void
  selected: boolean
  reserved: boolean
  disabled: boolean
  style: StyleProp<ViewStyle>
}

export default class WeekCalendarDay extends Component<Props> {
  render() {
    const { dayName, dayDigit, monthName, onPress, selected, reserved, disabled, style } = this.props

    let selectedColor = selected ? config.theme.colors.primary : config.theme.colors.text
    let reservedColor = reserved ? config.theme.colors.success : config.theme.colors.primary
    if (selected && reserved) selectedColor = reservedColor

    const dayNameStyle: TextStyle = {
      fontSize: 10,
      lineHeight: 10,
      fontWeight: 'bold',
      color: selectedColor,
      opacity: selected ? 1 : 0.4,
    }

    const dayDigitStyle: TextStyle = {
      fontSize: 18,
      lineHeight: 18,
      fontWeight: 'bold',
      marginTop: 3,
      color: selectedColor,
    }

    return (
      <Box style={[{ width: 42, height: 56, borderRadius: 4, transform: [{ scale: selected ? 1.1 : 1 }] }, style]}>
        <Box disabled={disabled} onPress={onPress} activeOpacity={1}>
          <Box
            style={{
              flex: 1,
              backgroundColor: config.theme.colors.inverted,
              borderWidth: StyleSheet.hairlineWidth,
              borderBottomWidth: 0,
              borderColor: selected ? selectedColor : config.theme.colors.border,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              paddingTop: 6,
              paddingBottom: 4,
            }}
          >
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={dayNameStyle}>{dayName}</Text>
              <Text style={dayDigitStyle}>{dayDigit}</Text>
            </View>
          </Box>
          <View
            style={{
              alignItems: 'center',
              backgroundColor: reserved ? reservedColor : config.theme.colors.primary,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              paddingVertical: StyleSheet.hairlineWidth,
            }}
          >
            <Text style={{ fontSize: 10, fontWeight: 'bold', color: config.theme.colors.inverted }}>{monthName}</Text>
          </View>
        </Box>
      </Box>
    )
  }
}
