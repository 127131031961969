import React from 'react'
import { Image } from 'react-native'
import Box from '../Box'

interface Props {
  type: 'left' | 'right'
  onPress: () => void
  disabled?: boolean
}

export default function ArrowButton({ type, onPress, disabled }: Props) {
  const icon = type === 'left' ? require('../../assets/arrow-left.png') : require('../../assets/arrow-right.png')
  const style = type === 'left' ? { marginRight: 15 } : { marginLeft: 15 }
  return (
    <Box style={style} onPress={onPress} disabled={disabled}>
      <Image source={icon} style={{ width: 34, height: 34 }} />
    </Box>
  )
}
