import React, { PureComponent } from 'react'
import { StyleSheet, Text as TextCore, TextProps, StyleProp, TextStyle, Platform } from 'react-native'

const isAndroid = Platform.OS === 'android'

interface Props extends TextProps {
  flex?: boolean | number
  size?: number
  thin?: boolean
  light?: boolean
  bold?: boolean
  heavy?: boolean
  center?: boolean
  white?: boolean
  upper?: boolean
  width?: number
  style?: StyleProp<TextStyle>
}

export default class Text extends PureComponent<Props> {
  /**
   * Support numeric fontWeights on android
   */
  fixAndroidFontWeights(style: TextStyle): TextStyle {
    if (!isAndroid || !style) return style

    const fontWeights = ['100', '200', '300', '400', '500', '600', '700', '800', '900']

    if (fontWeights.includes(String(style.fontWeight))) {
      switch (style.fontWeight) {
        case '100':
        case '200':
          style.fontFamily = 'sans-serif-thin'
          style.fontWeight = 'normal'
          break
        case '300':
          style.fontFamily = 'sans-serif-light'
          style.fontWeight = 'normal'
          break
        case '400':
          style.fontFamily = 'sans-serif'
          style.fontWeight = 'normal'
          break
        case '500':
        case '600':
        case '700':
          style.fontFamily = 'sans-serif-medium'
          style.fontWeight = 'normal'
          break
        case '800':
          style.fontFamily = 'sans-serif'
          style.fontWeight = 'bold'
          break
        case '900':
          style.fontFamily = 'sans-serif-medium'
          style.fontWeight = 'bold'
          break
      }
    }

    return style
  }

  render() {
    const { size, bold, heavy, thin, light, upper, white, center, style, flex, width, ...moreProps } = this.props

    let headings: StyleProp<TextStyle> = {}

    if (size) {
      headings.fontSize = size
    }

    if (thin) {
      headings.fontFamily = isAndroid ? 'sans-serif-thin' : undefined
      headings.fontWeight = isAndroid ? 'normal' : '200'
    }

    if (light) {
      headings.fontFamily = isAndroid ? 'sans-serif-light' : undefined
      headings.fontWeight = isAndroid ? 'normal' : '300'
    }

    if (bold) {
      headings.fontFamily = isAndroid ? 'sans-serif-medium' : undefined
      headings.fontWeight = isAndroid ? 'normal' : '700'
    }

    if (heavy) {
      headings.fontFamily = isAndroid ? 'sans-serif-medium' : undefined
      headings.fontWeight = isAndroid ? 'bold' : '900'
    }

    if (center) {
      headings.textAlign = 'center'
    }

    if (width) {
      headings.width = width
    }

    if (flex !== undefined) {
      headings.flex = Number(flex)
    }

    if (white) {
      headings.color = 'white'
    }

    const customStyle = this.fixAndroidFontWeights(style as TextStyle)

    const content =
      upper === true && typeof this.props.children === 'string'
        ? this.props.children.toUpperCase()
        : this.props.children

    return (
      <TextCore style={[s.text, headings, customStyle]} {...moreProps}>
        {content}
      </TextCore>
    )
  }
}

const s = StyleSheet.create({
  text: {
    fontSize: 16,
    color: '#222222',
    backgroundColor: 'transparent',
  },
})
