import React, { Component } from 'react'
import { StyleSheet, Dimensions } from 'react-native'
import config from '../../shared/config'
import { Box } from '../'
import moment from 'moment'

import ArrowButton from './ArrowButton'
import WeekCalendarDay from './WeekCalendarDay'
import DayReservationPopup from './DayReservationPopup'
import { AppStore, showError } from '../../shared'

const { width } = Dimensions.get('window')
const isMobile = width <= 375

interface Props {
  onDaySelected: (day: ReservationDayId) => void
}

interface State {
  weeksDiffCount: number
  selectedDay?: ReservationDayId
}

export default class WeekCalendar extends Component<Props, State> {
  state: State = {
    weeksDiffCount: this.getWeekToFetch(),
    selectedDay: undefined,
  }

  getWeekToFetch() {
    const isWeekend = moment().day() === 6 || moment().day() === 7
    return isWeekend ? 1 : 0
  }

  selectDay = (selectedDay: string) => {
    this.setState({
      selectedDay: this.state.selectedDay === selectedDay ? undefined : selectedDay,
    })
    this.props.onDaySelected(selectedDay)
  }

  prevWeek = () => this.addWeek(-1)
  nextWeek = () => {
    const maxWeeks = AppStore.remoteConfig?.global.max_reservable_weeks ?? 100
    if (this.state.weeksDiffCount >= maxWeeks) {
      showError(`Non puoi prenotare più di ${maxWeeks} settimane avanti.`)
      return
    }

    this.addWeek(1)
  }

  addWeek = (weeks: number) =>
    this.setState({ weeksDiffCount: this.state.weeksDiffCount + weeks, selectedDay: undefined })

  addReservation = () => {
    const { selectedDay } = this.state
    if (!selectedDay) return
    AppStore.addUserReservation(selectedDay)
    this.setState({ selectedDay: undefined })
  }

  removeReservation = () => {
    const { selectedDay } = this.state
    if (!selectedDay) return
    AppStore.removeUserReservation(selectedDay)
    this.setState({ selectedDay: undefined })
  }

  render() {
    const { weeksDiffCount, selectedDay } = this.state
    const reservedDays: ReservationDayId[] = AppStore.userData?.reservations ?? []

    const firstDayOfWeek = moment().day('Monday').add(weeksDiffCount, 'weeks')

    const daysOfWeek = new Array(5).fill({}).map((v, i) => ({
      id: firstDayOfWeek.day(i + 1).format('DD-MM-YYYY'),
      dayName: firstDayOfWeek.day(i + 1).format('ddd'),
      dayDigit: firstDayOfWeek.day(i + 1).format('DD'),
      monthName: firstDayOfWeek.day(i + 1).format('MMM'),
      disabled: moment(new Date()).isAfter(firstDayOfWeek.day(i + 1), 'day'),
    }))

    const alreadyReserved = selectedDay && AppStore.userData?.reservations?.includes(selectedDay)

    return (
      <>
        <Box row center style={s.box}>
          <ArrowButton type="left" onPress={this.prevWeek} disabled={weeksDiffCount <= 0} />

          {/* render 5 boxes for current week days */}
          {daysOfWeek.map(({ id, dayName, dayDigit, monthName, disabled }, idx) => (
            <Box key={id} animation="fadeIn" delay={idx * 50} duration={300}>
              <WeekCalendarDay
                onPress={() => this.selectDay(id)}
                dayName={dayName.toUpperCase()}
                dayDigit={dayDigit}
                monthName={monthName.toUpperCase()}
                reserved={reservedDays.includes(id)}
                selected={selectedDay === id}
                disabled={disabled}
                style={idx !== 0 ? { marginLeft: 10 } : {}}
              />
            </Box>
          ))}

          <ArrowButton type="right" onPress={this.nextWeek} />
        </Box>

        <DayReservationPopup
          day={selectedDay}
          onClose={() => this.setState({ selectedDay: undefined })}
          onConfirm={alreadyReserved ? this.removeReservation : this.addReservation}
        />
      </>
    )
  }
}

const s = StyleSheet.create({
  box: {
    maxHeight: 160,
    paddingVertical: 25,
    paddingHorizontal: isMobile ? 12 : 20,
    borderRadius: isMobile ? 0 : config.theme.radius * 2,
    backgroundColor: config.theme.colors.inverted,
    borderBottomColor: config.theme.colors.borderLight,
    borderBottomWidth: StyleSheet.hairlineWidth,
    position: 'relative',
    zIndex: 99,
  },
})
