import React, { PureComponent } from 'react'
import { StyleSheet, View, Text, ActivityIndicator, StyleProp, ViewStyle } from 'react-native'
import config from '../shared/config'

interface Props {
  message?: string
  style?: StyleProp<ViewStyle>
}

export default class Loader extends PureComponent<Props> {
  render() {
    const { message, style } = this.props
    return (
      <View style={[s.container, style]}>
        <ActivityIndicator size="large" color={config.theme.colors.primary} />
        {message && <Text style={s.message}>{message}</Text>}
      </View>
    )
  }
}

const s = StyleSheet.create({
  container: {
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  message: {
    marginTop: 8,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',
    color: config.theme.colors.primary,
    maxWidth: 150,
  },
})
