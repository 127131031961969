import AsyncStorage from '@react-native-community/async-storage'

class Storage {
  async save(key: string, data: any) {
    return AsyncStorage.setItem(key, JSON.stringify(data))
  }

  async load<T>(key: string, defaultValue?: T): Promise<T> {
    const data = await AsyncStorage.getItem(key)
    return data ? JSON.parse(data) : defaultValue
  }

  async remove(key: string) {
    return AsyncStorage.removeItem(key)
  }

  async clear(): Promise<void> {
    return AsyncStorage.clear()
  }
}

export default new Storage()
