import { makeAutoObservable, configure } from 'mobx'
import {
  auth,
  fetchRemoteConfig,
  fetchUserData,
  incrementDepartmentReservations,
  updateUserData,
  updateUserReservations,
  updateUserSignatures,
} from './firebase'

class AppStore {
  user?: firebase.User | null = undefined
  userData?: UserData = undefined
  remoteConfig?: RemoteConfig = undefined
  loadingUser: boolean = true

  // mobx actions //
  setUser = (data: firebase.User | null) => (this.user = data)
  setUserData = (data: UserData) => (this.userData = data)
  setRemoteConfig = (data: RemoteConfig) => (this.remoteConfig = data)
  setLoadingUser = (flag: boolean) => (this.loadingUser = flag)

  constructor() {
    configure({ useProxies: 'ifavailable' })
    auth.onAuthStateChanged(this.userChanged)
    makeAutoObservable(this)
  }

  private userChanged = async (user: firebase.User | null) => {
    if (user) {
      this.setLoadingUser(true)
      const remoteConfig = await fetchRemoteConfig()
      this.setRemoteConfig(remoteConfig)

      const userData = await fetchUserData()
      this.setUserData(userData)
    }

    this.setUser(user)
    this.setLoadingUser(false)
  }

  async updateUserData(data: UserData) {
    await updateUserData(data)
    this.setUserData(data)
  }

  async registerUserSignature(day: ReservationDayId) {
    if (!this.userData) throw new Error('No user data found')
    this.userData.signatures.push(day)
    return updateUserSignatures(this.userData.signatures)
  }

  async addUserReservation(day: ReservationDayId) {
    if (!this.userData) throw new Error('No user data found')
    this.userData.reservations.push(day)
    await incrementDepartmentReservations(1, day, this.userData.department_id)
    return updateUserReservations(this.userData.reservations)
  }

  async removeUserReservation(day: ReservationDayId) {
    if (!this.userData?.reservations) throw new Error('No user or user.reservations found')
    this.userData.reservations = this.userData.reservations.filter(d => d !== day)
    await incrementDepartmentReservations(-1, day, this.userData.department_id)
    return updateUserReservations(this.userData.reservations)
  }
}

export default new AppStore()
