import React, { Component } from 'react'
import { StyleSheet, View, Image, Linking } from 'react-native'
import {
  sendEmailMagicLink,
  loginWithEmailLink,
  foundEmailToken,
  loginWithGoogle,
  logout,
  loginWithAzure,
  getCurrentUser,
  linkToAzure,
} from '../shared/firebase'
import { Box, Button, SignUpForm, Input, Text, WeekCalendar, UserReservationRow } from '../components'
import { showError, AppStore, getDayId, Storage } from '../shared'
import { observer } from 'mobx-react'
import config from '../shared/config'
import moment from 'moment'

const colors = config.theme.colors

interface State {
  selectedDay: ReservationDayId
  showEmailLogin: boolean
  emailSent: boolean
  email: string
  accountLinked: boolean
}

@observer
export default class Login extends Component<{}, State> {
  state: State = {
    selectedDay: getDayId(),
    showEmailLogin: false,
    emailSent: false,
    email: '',
    accountLinked: false,
  }

  componentDidMount() {
    this.checkVerifyEmailLink()
  }

  async checkVerifyEmailLink() {
    if (AppStore.user) return
    if (!(await foundEmailToken())) return

    try {
      this.login(() => loginWithEmailLink())
    } catch (error: any) {
      showError(error.message)
    }
  }

  async login(callablePromise: any) {
    try {
      AppStore.setLoadingUser(true)
      await callablePromise()
    } catch (error: any) {
      console.error(error)
      if (error.code === 'auth/account-exists-with-different-credential') {
        showError(
          'Esiste già un account con lo stesso indirizzo email ma con un altro provider di autenticazione. Accedi con il provider precedentemente utilizzato. Successivamente potrai linkare anche il seguente provider.'
        )
      } else {
        showError(error.message)
      }
      AppStore.setLoadingUser(false)
    }
  }

  googleLogin = () => this.login(loginWithGoogle)
  microsoftLogin = () => this.login(loginWithAzure)
  emailLogin = () => this.setState({ showEmailLogin: true })
  sendEmailLink = async () => {
    try {
      await sendEmailMagicLink(this.state.email)
      await Storage.save('email', this.state.email)
      this.setState({ emailSent: true })
    } catch (error: any) {
      showError(error.message)
    }
  }

  getUserReservations() {
    const dateIsNotTomorrow = (d: ReservationDayId) => moment(d, 'DD-MM-YYYY').diff(moment(new Date()), 'day') >= 0
    const sortByDate = (a: string, b: string) => {
      const aSortable = moment(a, 'DD-MM-YYYY').format('YYYYMMDD')
      const bSortable = moment(b, 'DD-MM-YYYY').format('YYYYMMDD')
      return aSortable > bSortable ? 1 : -1
    }
    return AppStore?.userData?.reservations.filter(dateIsNotTomorrow).slice().sort(sortByDate) ?? []
  }

  goHome = async () => Linking.openURL('/')
  logout = async () => logout().catch(e => showError(e.message))
  openDayDetails = (selectedDay: ReservationDayId) => this.setState({ selectedDay })

  render() {
    const { user, userData } = AppStore
    const { showEmailLogin, emailSent, accountLinked } = this.state

    const shouldFillForm = user && !userData?.department_id
    const userReservations = this.getUserReservations()

    // const temeraAzureAccountLinked =
    //   user &&
    //   !!getCurrentUser()?.providerData.find(p => p?.providerId === 'microsoft.com') &&
    //   getCurrentUser()?.email?.endsWith('@temera.it')

    const infoMessage = shouldFillForm
      ? `Inserisci le informazioni sotto per continuare`
      : `Seleziona un giorno per prenotare  la tua presenza in ufficio.`

    return (
      <View style={s.screen}>
        <Box animation="zoomIn" onPress={this.goHome}>
          <Image source={require('../assets/logo-new.png')} style={{ width: 256, height: 64 }} />
        </Box>

        {!user &&
          !showEmailLogin && ( // 1. User login required (google or showEmailLogin)
            <>
              <Box animation="zoomIn" delay={300} style={{ marginTop: '5%' }}>
                <Text style={s.info}>Ciao! Se sei un dipendente Temera accedi con Google.</Text>
              </Box>
              <Box loading={AppStore.loadingUser} animation="zoomIn" delay={500} style={{ marginTop: '10%' }}>
                {/* <Button title="Accedi con Microsoft" onPress={this.microsoftLogin} style={s.btn} /> */}
                <Button title="Accedi con Google" onPress={this.googleLogin} style={s.btn} />
                <Button title="Accedi con Email" variant="outline" onPress={this.emailLogin} style={s.btn} />
              </Box>
            </>
          )}

        {!user &&
          showEmailLogin && ( // 2. User login via email link
            <>
              {!emailSent && ( // 2a. ask user to insert email address
                <Box animation="zoomIn" duration={400}>
                  <Text style={s.info}>Inserisci il tuo indirizzo email per continuare</Text>
                  <Input
                    placeholder="Email (es. arca@temera.it)"
                    defaultValue={this.state.email}
                    onChangeText={email => this.setState({ email })}
                    keyboardType="email-address"
                    onSubmitEditing={this.sendEmailLink}
                    style={{ width: 300, marginBottom: 20 }}
                  />
                  <Button title="Invia" onPress={this.sendEmailLink} style={{ width: 300 }} />
                </Box>
              )}

              {emailSent && ( // 2b. tell user to check email address
                <Box animation="zoomIn" duration={400}>
                  <Text style={s.info}>
                    Controlla la tua casella email e segui le istruzioni per accedere al sistema.
                  </Text>
                </Box>
              )}
            </>
          )}

        {user && ( // 2. user authenticated
          <>
            <Box animation="zoomIn" delay={200}>
              <Text style={s.info}>{infoMessage}</Text>

              {/* Permetti di associare l'acount SSO Temera se l'accesso è stato effettuato con dominio temera */}

              {/* {!temeraAzureAccountLinked && !accountLinked && (
                <Text
                  style={[s.smallText, s.link, s.vspace]}
                  onPress={() => {
                    linkToAzure()
                    this.setState({ accountLinked: true })
                  }}
                >
                  Associa account SSO Temera (Microsoft)
                </Text>
              )} */}
            </Box>

            {/* 3. Aggiungi dati mancanti per l'utente */}
            {shouldFillForm && (
              <Box animation="fadeInUp" delay={500}>
                <SignUpForm />
              </Box>
            )}

            {!shouldFillForm && (
              <>
                <Box animation="zoomIn" delay={500} duration={300} zIndex={999}>
                  <WeekCalendar onDaySelected={this.openDayDetails} />
                </Box>

                {userReservations.length > 0 && (
                  <Box style={{ marginTop: 25 }} animation="zoomIn" delay={700} duration={300}>
                    <Text style={{ fontSize: 18, fontWeight: '600', opacity: 0.8, textAlign: 'center' }}>
                      Prossime presenze
                    </Text>
                    <Box
                      style={{
                        marginTop: 10,
                        borderRadius: config.theme.radius,
                        borderColor: colors.borderLight,
                        borderWidth: StyleSheet.hairlineWidth,
                        backgroundColor: colors.inverted,
                      }}
                    >
                      {userReservations.map((day, i) => (
                        <UserReservationRow
                          animation="fadeIn"
                          delay={i * 50}
                          key={day}
                          day={day}
                          // onPress={() => alert(day)}
                          onDeletePress={() => AppStore.removeUserReservation(day)}
                          style={{
                            borderBottomColor: colors.borderLight,
                            borderBottomWidth: StyleSheet.hairlineWidth,
                            borderBottomLeftRadius: i === userReservations.length - 1 ? undefined : 0,
                            borderBottomRightRadius: i === userReservations.length - 1 ? undefined : 0,
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </>
            )}

            <Box flex />

            <Box animation="zoomIn" delay={800} style={{ marginVertical: 15 }}>
              <Text style={s.smallText}>
                Login effettuato come{' '}
                <Text style={[s.smallText, { fontWeight: '600' }]}>{user?.displayName || userData?.name}</Text>.{' '}
                <Text style={[s.smallText, s.logout]} onPress={this.logout}>
                  Effettua Logout
                </Text>
              </Text>
            </Box>
          </>
        )}
      </View>
    )
  }
}

const s = StyleSheet.create({
  screen: { flex: 1, maxWidth: 300, alignSelf: 'center', alignItems: 'center', marginTop: '7%' },
  info: { width: 280, fontSize: 18, fontWeight: '300', textAlign: 'center', marginTop: '5%', marginBottom: '10%' },
  smallText: { fontSize: 14, fontWeight: '300', textAlign: 'center' },
  btn: { marginBottom: 12, width: 240 },
  logout: { color: colors.error, fontWeight: '600' },
  link: { color: colors.primary, fontWeight: '600' },
  vspace: { marginVertical: 20 },
})
