import React from 'react'
import { StyleProp, ViewStyle, TouchableOpacity, StyleSheet, View } from 'react-native'
import * as Animatable from 'react-native-animatable'
import Loader from './Loader'

export interface BoxProps extends Omit<Animatable.AnimatableProperties<{}>, 'animation'> {
  row?: boolean
  flex?: boolean | number
  padding?: number
  margin?: number
  radius?: number
  zIndex?: number
  center?: boolean
  loading?: boolean
  loadingMessage?: string
  activeOpacity?: number
  bg?: string
  width?: number
  onPress?: () => void
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  animation?: Animatable.Animation
}

const Box: React.FC<BoxProps> = ({
  children,
  center,
  row,
  flex,
  padding,
  margin,
  radius,
  zIndex,
  bg,
  loading,
  width,
  loadingMessage,
  activeOpacity,
  style,
  onPress,
  disabled,
  ...rest
}) => {
  const defaultDuration = rest.animation ? 600 : undefined
  const centerStyle: ViewStyle = center ? s.center : {}
  const flexStyle: ViewStyle = flex !== undefined ? { flex: Number(flex) } : {}
  const backgroundStyle: ViewStyle = bg ? { backgroundColor: bg, width } : {}
  const loadingStyle: ViewStyle = { opacity: loading ? 0.15 : 1, flex: 1 }

  const extraStyle: ViewStyle = {}
  if (padding) extraStyle.padding = padding
  if (margin) extraStyle.margin = margin
  if (radius) extraStyle.borderRadius = radius
  if (row) extraStyle.flexDirection = 'row'
  if (disabled) extraStyle.opacity = 0.5

  const extraContainerStyle: ViewStyle = {}
  if (zIndex) extraContainerStyle.zIndex = zIndex

  let content = (
    <Animatable.View
      duration={defaultDuration}
      style={[flexStyle, extraContainerStyle]}
      {...(rest as Animatable.AnimatableProperties<{}>)}
    >
      {loading && <Loader message={loadingMessage} style={s.loader} />}
      <View style={[centerStyle, flexStyle, backgroundStyle, loadingStyle, extraStyle, style]}>{children}</View>
    </Animatable.View>
  )

  return onPress ? (
    <TouchableOpacity onPress={onPress} activeOpacity={activeOpacity ?? 0.7} style={flexStyle} disabled={disabled}>
      {content}
    </TouchableOpacity>
  ) : (
    content
  )
}

const s = StyleSheet.create({
  touchable: { backgroundColor: 'transparent' },
  center: { alignItems: 'center', justifyContent: 'center' },
  loader: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999 },
})

export default Box
