import React from 'react'
import { TouchableOpacity, StyleProp, ViewStyle, StyleSheet, ActivityIndicator, TextStyle } from 'react-native'
import config from '../shared/config'
import Text from './Text'

interface Props {
  title: string
  color?: string
  variant?: 'primary' | 'secondary' | 'danger' | 'outline' | 'link'
  size?: 'normal' | 'small'
  onPress: () => void
  loading?: boolean
  disabled?: boolean
  style?: StyleProp<ViewStyle>
}

export default function Button({ title, color, variant = 'primary', size, onPress, loading, disabled, style }: Props) {
  let extraBoxStyle: ViewStyle = {}
  let extraTextStyle: TextStyle = {}
  const disabledStyle: StyleProp<ViewStyle> = loading || disabled ? { opacity: 0.4 } : {}
  const buttonColor = color ?? config.theme.colors.primary

  if (variant === 'link') {
    extraBoxStyle.backgroundColor = 'transparent'
    extraBoxStyle.borderRadius = 0
    extraBoxStyle.borderColor = 'transparent'
    extraTextStyle.color = buttonColor
    extraBoxStyle.shadowOffset = undefined
    extraBoxStyle.shadowColor = 'transparent'
  }

  if (variant === 'outline') {
    extraBoxStyle.backgroundColor = 'transparent'
    extraTextStyle.color = buttonColor
    extraBoxStyle.shadowOffset = undefined
    extraBoxStyle.shadowColor = 'transparent'
  }

  if (variant === 'danger') {
    extraBoxStyle.backgroundColor = config.theme.colors.error
    extraBoxStyle.shadowColor = config.theme.colors.error
    extraBoxStyle.borderColor = config.theme.colors.error
  }

  if (variant === 'secondary') {
    extraBoxStyle.backgroundColor = config.theme.colors.secondary
    extraBoxStyle.shadowColor = config.theme.colors.secondary
    extraBoxStyle.borderColor = config.theme.colors.secondary
  }

  if (size === 'small') {
    extraBoxStyle.paddingVertical = 10
    extraBoxStyle.paddingHorizontal = 10
    extraBoxStyle.minWidth = 'auto'
    extraTextStyle.fontSize = 16
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={!!disabled || !!loading}
      style={[s.button, extraBoxStyle, style, disabledStyle]}
      activeOpacity={0.7}
    >
      {loading && <ActivityIndicator size="small" color="white" />}
      {!loading && <Text style={[s.text, extraTextStyle]}>{title}</Text>}
    </TouchableOpacity>
  )
}

const s = StyleSheet.create({
  button: {
    minWidth: 180,
    paddingVertical: 13,
    paddingHorizontal: 22,
    backgroundColor: config.theme.colors.primary,
    borderColor: config.theme.colors.primary,
    borderWidth: 2,
    borderRadius: config.theme.radius,
    shadowColor: config.theme.colors.primary,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3.0,
  },
  text: {
    color: 'white',
    fontSize: 17,
    fontWeight: '600',
    textAlign: 'center',
  },
})
