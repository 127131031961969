export default {
  firebase: {
    apiKey: 'AIzaSyAO5inuKJPhMDWKSaLMb2ltfL837MSeqFA',
    authDomain: 'temeralife.firebaseapp.com',
    databaseURL: 'https://temeralife.firebaseio.com',
    projectId: 'temeralife',
    storageBucket: 'temeralife.appspot.com',
    messagingSenderId: '832833599402',
    appId: '1:832833599402:web:0f5e9187682dd817d11dd3',
    measurementId: 'G-47N2K9Q2HF',
    // custom params //
    actionCodeSettings: {
      // Host Domain must be in the authorized domains list
      url: __DEV__ ? 'http://localhost:19006/signup' : 'https://life.temera.it/signup',
      handleCodeInApp: true,
      // Configurate mobile configuration later...
      // iOS: { bundleId: 'it.temera.life' },
      // android: { packageName: 'it.temera.life', installApp: true, minimumVersion: '12' },
      // dynamicLinkDomain: 'temeralife.web.app/deepLink',
    } as firebase.auth.ActionCodeSettings,
  },
  theme: {
    colors: {
      background: '#f5f5f5',
      background2: '#EEEEEE',
      inverted: '#ffffff',
      primary: '#18A0FB',
      secondary: '#98A8B2',
      text: '#222222',
      error: '#DF3131',
      warning: '#F99603',
      warning2: '#FEDB22',
      success: '#01C868',
      border: '#d2d6dc',
      borderLight: '#E7E7E7',
      borderLighter: '#f1f1f1',
      placeholder: '#AAB1BB',
    },
    radius: 6,
    radiusBig: 10,
    padding: 13,
  },
}
