import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { Input, Box, Button, Select } from './index'
import { AppStore, showError, Storage } from '../shared'
import { SelectItem } from './Select'

type State = {
  name: string
  company: string
  department?: Department
  loading: boolean
}

export default class SignUpForm extends Component<{}, State> {
  state: State = {
    name: '',
    company: '',
    department: undefined,
    loading: false,
  }

  departments = AppStore.remoteConfig?.global.departments ?? []

  onChange = (stateParam: keyof State, value: any) => {
    // @ts-ignore
    this.setState({ [stateParam]: value })
  }

  submit = async () => {
    try {
      if (!this.state.name && !AppStore.user?.displayName) throw new Error('Inserisci il tuo nome')
      if (!this.state.department) throw new Error('Inserisci il reparto di riferimento')
      this.setState({ loading: true })

      let userData: UserData = {
        ...AppStore.userData,
        uid: AppStore.user!.uid,
        department_id: this.state.department?.code,
        reservations: [],
      }

      userData.name = this.state.name || AppStore.user?.displayName
      userData.company = this.state.company || AppStore.user?.email?.includes('@temera.it') ? 'Temera' : ''
      userData.email = AppStore.user?.email || 'unknown'

      Storage.save('email', userData.email)

      if (AppStore.user?.displayName !== userData.name) {
        AppStore.user?.updateProfile({ displayName: userData.name })
      }

      await AppStore.updateUserData(userData)
    } catch (err) {
      this.setState({ loading: false })
      showError(err.message)
    }
  }

  departmentSelected = (dep: SelectItem) =>
    this.setState({ department: this.departments.find(d => d.code === dep.value) })

  render() {
    const name = AppStore.user?.displayName ?? AppStore.userData?.name
    const items = this.departments.map(d => ({ label: d.name, value: d.code, key: d.code })) ?? []

    return (
      <Box loading={this.state.loading}>
        {!name && (
          <>
            <Input
              placeholder="Nome completo"
              defaultValue={this.state.name}
              onChangeText={name => this.onChange('name', name)}
              style={s.input}
            />
            <Input
              placeholder="Azienda (opzionale)"
              defaultValue={this.state.company}
              onChangeText={company => this.onChange('company', company)}
              style={s.input}
            />
          </>
        )}

        <Select
          placeholder="Seleziona reparto di interesse"
          items={items}
          selectedValue={this.state.department?.code}
          onItemSelected={this.departmentSelected}
        />
        <Button title="Avanti" onPress={this.submit} style={s.btn} />
      </Box>
    )
  }
}

const s = StyleSheet.create({
  input: { width: 300, marginBottom: 10 },
  btn: { width: 300, marginVertical: 20 },
})
