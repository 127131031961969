import React, { Component } from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Box, Text, Button } from '../'
import { AppStore, arrayChunk, showError, sumObjectValues } from '../../shared'
import { fetchReservationStats } from '../../shared/firebase'
import DayReservationUsersModal from './DayReservationUsersModal'
import config from '../../shared/config'

interface State {
  reservationStats?: ReservationStats
  showReservationsModal: boolean
}

interface Props {
  day?: ReservationDayId
  onClose: () => void
  onConfirm: () => void
  style?: StyleProp<ViewStyle>
}

export default class DayReservationPopup extends Component<Props, State> {
  state: State = {
    reservationStats: undefined,
    showReservationsModal: false,
  }

  filterByDepartmentCode?: string = undefined

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.day && nextProps.day !== this.props.day) {
      this.fetchReservationForDay(nextProps.day!)
    }
  }

  async fetchReservationForDay(day: ReservationDayId) {
    try {
      let reservationStats = await fetchReservationStats(day)
      this.setState({ reservationStats })
    } catch (err) {}
  }

  reserveSlot = () => {}

  onConfirm = () => {
    const { day } = this.props
    const { reservationStats } = this.state
    const totalAvailableCount = AppStore.remoteConfig?.global.available_slots ?? 0
    const totalReservedCount = reservationStats ? sumObjectValues(reservationStats.reserved_by_department ?? {}) : 0
    const dayAlreadyReserved = day && AppStore.userData?.reservations?.includes(day)

    if (!dayAlreadyReserved && totalReservedCount >= totalAvailableCount) {
      showError(
        'Numero massimo di presenze raggiunto. Se la tua presenza è estremamente necessaria, guarda chi si è prenotato e organizzati per uno scambio.'
      )
      return
    }

    this.props.onConfirm()
  }

  render() {
    const { day, style } = this.props
    const { reservationStats, showReservationsModal } = this.state

    const departments = AppStore.remoteConfig?.global.departments ?? []
    const totalAvailableCount = AppStore.remoteConfig?.global.available_slots ?? 0
    const totalReservedCount = reservationStats ? sumObjectValues(reservationStats.reserved_by_department ?? {}) : 0
    const dayAlreadyReserved = day && AppStore.userData?.reservations?.includes(day)

    if (!day) return true

    return (
      <Box style={[s.box, style]} animation={'fadeInUp'} duration={200}>
        {/* Modal for reserved users */}
        <DayReservationUsersModal
          day={showReservationsModal ? day : undefined}
          department={this.filterByDepartmentCode}
          onClose={() => {
            this.filterByDepartmentCode = undefined
            this.setState({ showReservationsModal: false })
          }}
        />

        <Box row flex padding={10} style={{ flexWrap: 'wrap' }}>
          {arrayChunk(departments, 3).map((group: Department[], i) => (
            <Box key={String(i)} row>
              {group.map((dep, j) => {
                const isFirst = i === 0 && j === 0
                const reservedCount = reservationStats?.reserved_by_department[dep.code] ?? 0
                const overTextStyle = reservedCount > dep.slots ? { color: config.theme.colors.error } : {}
                const extraStyle = {
                  marginLeft: j !== 0 ? 1 : 0, // first of first row
                  borderTopLeftRadius: isFirst ? 8 : 0,
                  borderTopRightRadius: i === 0 && j === group.length - 1 ? 8 : 0,
                  borderBottomLeftRadius: i === Math.round(departments.length / 3) - 1 && j === 0 ? 8 : 0,
                }
                return (
                  <Box
                    key={dep.code}
                    style={[s.depBox, extraStyle]}
                    onPress={() => {
                      this.filterByDepartmentCode = dep.code
                      this.setState({ showReservationsModal: true })
                    }}
                  >
                    <Text style={s.depBoxText1}>{dep.short_name}</Text>
                    <Text style={[s.depBoxText2, overTextStyle]}>
                      {reservedCount}/{dep.slots}
                    </Text>
                  </Box>
                )
              })}
            </Box>
          ))}

          <Box
            onPress={() => this.setState({ showReservationsModal: true })}
            style={[
              {
                marginTop: 2,
                width: 278,
                paddingHorizontal: 8,
                paddingVertical: 5,
                alignItems: 'flex-end',
                backgroundColor:
                  totalReservedCount > totalAvailableCount ? config.theme.colors.error : config.theme.colors.primary,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              },
            ]}
          >
            <Text style={[s.depBoxText1, { color: config.theme.colors.inverted }]}>{'Totale'}</Text>
            <Text style={[s.depBoxText2, { color: config.theme.colors.inverted }]}>
              {totalReservedCount}/{totalAvailableCount}
            </Text>
          </Box>
        </Box>

        <Box row padding={10} style={{ borderTopWidth: 1, borderTopColor: config.theme.colors.borderLighter }}>
          <Button size="small" variant="secondary" title="Chiudi" onPress={this.props.onClose} />
          <Button
            size="small"
            title={dayAlreadyReserved ? 'Annulla Presenza' : 'Conferma Presenza'}
            onPress={this.onConfirm}
            variant={dayAlreadyReserved ? 'danger' : 'primary'}
            style={s.btnConfirm}
          />
        </Box>
      </Box>
    )
  }
}

const s = StyleSheet.create({
  box: {
    flex: 1,
    alignSelf: 'center',
    width: 300,
    elevation: 1,
    backgroundColor: config.theme.colors.inverted,
    borderRadius: config.theme.radiusBig,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: config.theme.colors.borderLight,
    position: 'absolute',
    top: -18,
  },
  depBox: {
    width: 92,
    marginLeft: 1,
    marginBottom: 1,
    backgroundColor: config.theme.colors.background2,
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 10,
  },
  depBoxText1: {
    fontSize: 11,
    fontWeight: '600',
  },
  depBoxText2: {
    fontSize: 20,
    fontWeight: '800',
  },
  btnConfirm: {
    marginLeft: 9,
    flex: 1,
  },
})
