import React, { Component } from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle, View, Image, Platform } from 'react-native'
import { PickerItemProps } from '@react-native-picker/picker/typings/Picker'
import { Picker } from '@react-native-picker/picker'
import config from '../shared/config'

export type SelectItem = PickerItemProps

interface Props {
  items: SelectItem[]
  placeholder?: string
  selectedValue?: string | number
  disabled?: boolean
  onItemSelected: (item: SelectItem) => void
  style?: StyleProp<ViewStyle>
}

export default class Select extends Component<Props> {
  render() {
    const defaultStyles: ViewStyle & TextStyle & React.CSSProperties = {
      backgroundColor: 'white',
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.05,
      shadowRadius: 2,
      paddingVertical: 13,
      paddingHorizontal: 8,
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: config.theme.colors.border,
      borderRadius: config.theme.radius,
      fontSize: 17,
      color: '#333',
      fontWeight: 'normal',
      WebkitAppearance: 'none',
    }

    return (
      <View>
        <View
          style={{
            width: 10,
            backgroundColor: 'white',
            top: 13,
            right: 5,
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingRight: 5,
          }}
        >
          <Image source={require('../assets/arrow-down.png')} style={{ width: 24, height: 24 }} resizeMode="contain" />
        </View>
        <Picker
          onValueChange={value =>
            value && this.props.onItemSelected(this.props.items.find(itm => itm.value === value)!)
          }
          selectedValue={this.props.selectedValue ?? ''}
          enabled={!this.props.disabled}
          style={defaultStyles}
        >
          {Platform.OS === 'web' && (
            <option disabled value="">
              {this.props.placeholder ?? 'Seleziona...'}
            </option>
          )}
          {Platform.OS !== 'web' && <Picker.Item label={this.props.placeholder ?? 'Seleziona...'} value={''} />}

          {this.props.items.map(itm => (
            <Picker.Item key={itm.value} label={itm.label} value={itm.value} />
          ))}
        </Picker>
      </View>
    )
  }
}
