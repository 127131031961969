import moment from 'moment'
import { Alert } from 'react-native'

export function getDayId(date?: Date): ReservationDayId {
  return moment(date).format('DD-MM-YYYY')
}

export const showError = (title = 'Errore', message = '', onButtonPress = undefined, buttonText = undefined): void => {
  // Alert.alert(title, message, [{ text: buttonText, onPress: onButtonPress }])
  delay(600).then(() => alert(title))
}

export const showMessage = (title: string, message?: string, onButtonPress?: any, buttonText?: string): void => {
  // Alert.alert(title, message, [{ text: buttonText, onPress: onButtonPress }])
  alert(title)
}

export const showPrompt = async (title: string, message?: string, onConfirm?: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Alert.prompt(title, message, text => {
    //   resolve(text)
    // })
    resolve(window.prompt(`${title} ${message}`) ?? '')
  })
}

export const delay = (ms: number): Promise<void> => new Promise(accept => setTimeout(() => accept(), ms))

export function arrayChunk<T>(array: T[], size: number): T[][] {
  if (!array) return []
  const firstChunk = array.slice(0, size) // create the first chunk of the given array
  if (!firstChunk.length) {
    // @ts-ignore
    return array // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(arrayChunk(array.slice(size, array.length), size))
}

export function sumObjectValues<T>(obj: T): number {
  return Object.values(obj).reduce((a, b) => a + b, 0)
}

class Group<T> {
  key: string
  elements: T[] = []
  constructor(key: string) {
    this.key = key
  }
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K) {
  const map = new Map<K, T[]>()
  list.forEach(item => {
    const key = getKey(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return Array.from(map.values())
}

export function areGeoPointsNear(checkPoint: GeoPoint, centerPoint: GeoPoint, km: number) {
  var ky = 40000 / 360
  var kx = Math.cos((Math.PI * centerPoint.latitude) / 180.0) * ky
  var dx = Math.abs(centerPoint.longitude - checkPoint.longitude) * kx
  var dy = Math.abs(centerPoint.latitude - checkPoint.latitude) * ky
  return Math.sqrt(dx * dx + dy * dy) <= km
}
