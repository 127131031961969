import { Linking } from 'react-native'
import * as firebase from 'firebase/app'
import config from './config'
import 'firebase/auth'
import 'firebase/firestore'
import Storage from './Storage'
import { showPrompt } from './utils'

// Initialize Firebase
const app = firebase.initializeApp(config.firebase)

export const db = firebase.firestore()
export const auth = firebase.auth()

let googleCredentials: firebase.auth.UserCredential | undefined = undefined
let emailCredentials: firebase.auth.UserCredential | undefined = undefined
let azureCredentials: firebase.auth.UserCredential | undefined = undefined

export function getTimeStamp() {
  return firebase.firestore.Timestamp.now()
}

export async function sendEmailMagicLink(email: string) {
  return firebase.auth(app).sendSignInLinkToEmail(email, config.firebase.actionCodeSettings)
}

export async function foundEmailToken() {
  const url = (await Linking.getInitialURL()) ?? ''
  return firebase.auth().isSignInWithEmailLink(url)
}

export async function loginWithEmailLink() {
  const url = (await Linking.getInitialURL()) ?? ''
  let email = await Storage.load('email', '')

  if (!email) {
    // if user opens a different browser we need to ask him the email address
    email = await showPrompt('Indirizzo Email', 'Per favore, inserisci la tua email per conferma')
  }

  emailCredentials = await firebase.auth().signInWithEmailLink(email, url)
  return emailCredentials
}

export async function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider()
  googleCredentials = await firebase.auth().signInWithPopup(provider)
  return googleCredentials
}

export const azureProvider = new firebase.auth.OAuthProvider('microsoft.com')
azureProvider.setCustomParameters({
  prompt: 'consent',
  login_hint: 'Usa la tua email aziendale per accedere. es. vasile.botoroga@temera.it',
  tenant: '86b81447-d27e-4e94-ace7-2dfab8b6c250',
})

export async function loginWithAzure() {
  try {
    azureCredentials = await firebase.auth().signInWithPopup(azureProvider)
    return azureCredentials
  } catch (error: any) {
    throw error
  }
}

export async function linkToAzure() {
  // to unlink via software: return firebase.auth().currentUser?.unlink(azureProvider.providerId)
  return firebase.auth().currentUser?.linkWithPopup(azureProvider)
}

export function getCurrentUser() {
  return firebase.auth().currentUser
}

export async function logout() {
  return firebase.auth().signOut()
}

export async function fetchUserData() {
  const uid = app.auth().currentUser?.uid
  const doc = await db.doc(`users/${uid}`).get()
  let data = <UserData>doc.data()
  if (data && !data.reservations) data.reservations = []
  if (data && !data.signatures) data.signatures = []

  return data
}

export async function fetchRemoteConfig() {
  const uid = app.auth().currentUser?.uid
  const global = await db.doc(`config/global`).get()
  const roles = await db.doc(`config/roles`).get()

  const remoteConfig: RemoteConfig = {
    global: <RemoteConfig['global']>global.data(),
    roles: <RemoteConfig['roles']>roles.data(),
  }

  return remoteConfig
}

export async function fetchReservation(date: ReservationDayId) {
  const uid = app.auth().currentUser?.uid
  const ref = await db.doc(`reservations/${date}`).get()
  return <Reservation>ref.data()
}

export async function fetchReservationStats(date: ReservationDayId) {
  const ref = await db.doc(`stats/${date}`).get()
  return <ReservationStats>ref.data()
}

export async function updateUserData(data: UserData) {
  const uid = app.auth().currentUser?.uid
  await db.doc(`users/${uid}`).set(data)
}

export async function updateUserReservations(reservations: ReservationDayId[]) {
  const uid = app.auth().currentUser?.uid

  return db.doc(`users/${uid}`).update({ reservations })
}

export async function updateUserSignatures(signatures: ReservationDayId[]) {
  const uid = app.auth().currentUser?.uid

  return db.doc(`users/${uid}`).update({ signatures })
}

export function incrementDepartmentReservations(slots: number, day: ReservationDayId, department: string) {
  return db
    .doc(`stats/${day}`)
    .set({ reserved_by_department: { [department]: firebase.firestore.FieldValue.increment(slots) } }, { merge: true })
}
