import React, { Component } from 'react'
import { StyleSheet, Platform, ScrollView } from 'react-native'
import { fetchReservation } from '../../shared/firebase'
import { AppStore, groupBy } from '../../shared'
import { Box, Text, Modal } from '../'
import config from '../../shared/config'
import moment from 'moment'

interface State {
  reservation?: Reservation
}

interface Props {
  day?: ReservationDayId
  department?: string // filter by department code
  onClose: () => void
}

export default class DayReservationUsersModal extends Component<Props, State> {
  state: State = { reservation: undefined }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.day && nextProps.day !== this.props.day) {
      this.fetchReservation(nextProps.day)
    }
  }

  async fetchReservation(day: ReservationDayId) {
    try {
      const reservation = await fetchReservation(day)
      this.setState({ reservation })
    } catch (err) {}
  }

  render() {
    const { day, department, onClose } = this.props
    const { reservation } = this.state
    const extraStyle: any = {}

    let reservations = reservation?.reserved_users ?? []

    if (department) {
      reservations = reservations.filter(u => u.department_id === department)
    }

    if (Platform.OS === 'web') extraStyle.cursor = 'default'

    if (!day) return true

    return (
      <Modal visible={true} onRequestClose={onClose} transparent={true} style={{ flex: 1 }}>
        <Box animation={'fadeIn'} style={s.overlay} duration={400} activeOpacity={1} onPress={onClose}>
          <Box animation="fadeInUp" delay={100} duration={300} center style={[s.box, extraStyle]}>
            <Box style={s.header}>
              <Text style={s.headerLabel}>Presenze</Text>
              <Text style={s.headerDate}>{moment(day, 'DD-MM-YYYY').format('dddd DD MMMM')}</Text>
            </Box>

            <ScrollView style={{ flex: 1 }}>
              {reservations.length === 0 && (
                <Box style={[s.row, { paddingVertical: 40 }]}>
                  <Text center>Nessuna presenza prenotata</Text>
                </Box>
              )}
              {groupBy(reservations, r => r.department_id).map(groupedItems => {
                const department = AppStore.remoteConfig?.global.departments.find(
                  d => d.code === groupedItems[0].department_id
                )
                return (
                  <Box flex key={groupedItems[0].department_id}>
                    <Box row style={s.section}>
                      <Text flex style={s.sectionText}>
                        {department?.name}{' '}
                      </Text>
                      {/* Show reserved vs available slots */}
                      {/* <Text style={s.sectionText2}>
                        {groupedItems.length}/{department?.slots}
                      </Text> */}
                    </Box>

                    {groupedItems.map((reservation, i) => (
                      <Box
                        key={reservation.user_id}
                        style={[s.row, { borderTopWidth: i === 0 ? 0 : StyleSheet.hairlineWidth }]}
                      >
                        <Text style={s.user}>{reservation.user_name}</Text>
                        <Text style={s.date}>{moment(reservation.reserved_on.toDate()).format('DD/MM HH:mm')}</Text>
                      </Box>
                    ))}
                  </Box>
                )
              })}
            </ScrollView>
          </Box>
          <Box animation="zoomIn" delay={300} duration={400} style={s.closeButton} onPress={onClose}>
            <Text style={s.closeText}>CHIUDI</Text>
          </Box>
        </Box>
      </Modal>
    )
  }
}

const s = StyleSheet.create({
  overlay: {
    display: 'flex',
    padding: 20,
    backgroundColor: 'rgba(15,26,33,0.6)',
    position: Platform.OS === 'web' ? 'fixed' : ('absolute' as any),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  box: {
    marginTop: '10%',
    flex: 1,
    borderRadius: 10,
    maxWidth: 340,
    minWidth: 300,
    maxHeight: 416,
    alignSelf: 'center',
    backgroundColor: config.theme.colors.inverted,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    minWidth: 300,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: config.theme.colors.borderLight,
  },
  headerLabel: {
    fontSize: 18,
    fontWeight: '600',
  },
  headerDate: {
    color: config.theme.colors.primary,
    fontSize: 14,
    fontWeight: '600',
  },
  section: {
    backgroundColor: config.theme.colors.primary,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  sectionText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '500',
  },
  sectionText2: {
    color: 'white',
    fontSize: 12,
    fontWeight: '700',
    opacity: 0.7,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    minWidth: 300,
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: 'center',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: config.theme.colors.borderLight,
  },
  user: {
    fontSize: 15,
    flex: 1,
  },
  date: {
    fontSize: 11,
    fontWeight: '600',
    opacity: 0.5,
  },
  closeButton: {
    width: 80,
    height: 80,
    padding: 30,
    marginTop: 30,
    marginBottom: 20,
    borderRadius: 40,
    backgroundColor: config.theme.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  closeText: {
    color: config.theme.colors.inverted,
    fontWeight: '800',
  },
})
