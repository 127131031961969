import React, { Component } from 'react'
import { Modal as MobileModal, Platform } from 'react-native'
import ReactDOM from 'react-dom'

class InnerModal extends Component<any> {
  el: HTMLDivElement
  appRoot: HTMLElement | null
  modalRoot: HTMLElement | null
  constructor(props: any) {
    super(props)
    // create and get reference to Modal DOM node
    this.appRoot = document.getElementById('root')
    this.appRoot?.insertAdjacentHTML('afterend', '<div id="modal-root"></div>')
    this.modalRoot = document.getElementById('modal-root')
    this.el = document.createElement('div')
  }

  componentDidMount() {
    // https://reactjs.org/docs/portals.html

    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot?.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot?.removeChild(this.el)
  }

  render() {
    const containerStyle = {
      // copied from RNW View/StyleSheet/constants
      alignItems: 'stretch',
      border: '0 solid black',
      boxSizing: 'border-box',
      display: 'flex',
      flexBasis: 'auto',
      flexDirection: 'column',
      flexShrink: 0,
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      minHeight: 0,
      minWidth: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      // position: 'relative',
      zIndex: 0,

      // modal
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,

      // etc
      backgroundColor: this.props.transparent ? 'transparent' : 'white',
    }

    return ReactDOM.createPortal(<div style={containerStyle as any}>{this.props.children}</div>, this.el)
  }
}

export default class Modal extends Component<any> {
  render() {
    if (Platform.OS !== 'web') return MobileModal

    if (this.props.visible) return <InnerModal {...this.props} />

    return null
  }
}
