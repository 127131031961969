import React, { Component } from 'react'
import { StyleProp, TextStyle, TextInputProps, TextInput, StyleSheet } from 'react-native'
import config from '../shared/config'

interface Props extends TextInputProps {
  onChangeText?: (text: string) => void
  debounceMilliseconds?: number
  style?: StyleProp<TextStyle>
}

export default class Input extends Component<Props> {
  timer?: any = undefined
  input: any // input ref

  setNativeProps(data = {}) {
    this.input.setNativeProps(data)
  }

  focus() {
    this.input.focus()
  }

  blur() {
    this.input.blur()
  }

  onChangeText = (text: string) => {
    const { onChangeText } = this.props
    if (onChangeText) {
      if (this.props.debounceMilliseconds != undefined) {
        this.timer && clearTimeout(this.timer)
        this.timer = undefined
        this.timer = setTimeout(() => onChangeText(text), this.props.debounceMilliseconds)
      } else {
        onChangeText(text)
      }
    }
  }

  render() {
    let defaultStyles: TextStyle = {
      fontSize: 17,
      color: '#333',
      fontWeight: '400',
      backgroundColor: 'white',
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.05,
      shadowRadius: 2,
      paddingVertical: 13,
      paddingHorizontal: 13,
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: config.theme.colors.border,
      borderRadius: config.theme.radius,
    }

    return (
      <TextInput
        ref={r => (this.input = r)}
        {...this.props}
        underlineColorAndroid={'transparent'}
        placeholderTextColor={config.theme.colors.placeholder}
        style={[defaultStyles, this.props.style]}
        onChangeText={this.onChangeText}
      >
        {this.props.children}
      </TextInput>
    )
  }
}
