import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { AppStore } from './shared'
import { Box } from './components'
import Login from './screens/Login'
import config from './shared/config'
import { observe } from 'mobx'

// configure moment locale
import moment from 'moment'
import 'moment/locale/it'
moment.locale('IT_it')

export default class App extends React.Component {
  state = { loading: true }

  constructor(props: any) {
    super(props)

    // will load app only when receiving response from firebase Auth State
    observe(AppStore, change => {
      if (change.type === 'update' && change.name === 'user') {
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { loading } = this.state
    return (
      <Box flex bg={config.theme.colors.background} loading={loading}>
        <StatusBar style="auto" />
        {!loading && <Login />}
      </Box>
    )
  }
}
