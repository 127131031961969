import React, { PureComponent } from 'react'
import { StyleSheet, StyleProp, ViewStyle, Image } from 'react-native'
import config from '../shared/config'
import Box, { BoxProps } from './Box'
import Text from './Text'
import moment from 'moment'

interface Props extends BoxProps {
  day?: ReservationDayId
  onPress?: () => void
  onDeletePress?: () => void
  style?: StyleProp<ViewStyle>
}

export default class UserReservationRow extends PureComponent<Props> {
  render() {
    const { day, onPress, onDeletePress, style, ...rest } = this.props
    return (
      <Box onPress={onPress} style={[s.box, style]} {...rest}>
        <Image source={require('../assets/icon-calendar.png')} style={{ width: 24, height: 24, margin: 12 }} />
        <Text style={s.day}>{moment(day, 'DD-MM-YYYY').format('dddd DD MMM')}</Text>
        <Box onPress={onDeletePress} style={{ padding: 12 }}>
          <Image source={require('../assets/icon-bin.png')} style={{ width: 24, height: 24 }} />
        </Box>
      </Box>
    )
  }
}

const s = StyleSheet.create({
  box: {
    width: 280,
    borderRadius: config.theme.radius,
    backgroundColor: config.theme.colors.inverted,
    flexDirection: 'row',
    alignItems: 'center',
  },
  day: {
    flex: 1,
    fontSize: 16,
    fontWeight: '500',
  },
})
